@use '@angular/material' as mat;
@include mat.core();

@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://api.mapbox.com/mapbox-gl-js/v2.14.1/mapbox-gl.css");

$light-theme-primary: mat.define-palette(mat.$indigo-palette); //mat-palette($mat-indigo);
$light-theme-accent: mat.define-palette(mat.$amber-palette);

$light-theme: mat.define-light-theme((color: (primary: $light-theme-primary,
        accent: $light-theme-accent ),
      typography: mat.define-typography-config(),
      density: 0));

// This makes light theme default
@include mat.all-component-themes($light-theme);

// Dark theme
$dark-theme-primary: mat.define-palette(mat.$grey-palette);
$dark-theme-accent: mat.define-palette(mat.$blue-grey-palette);

$dark-theme: mat.define-dark-theme((color: (primary: $dark-theme-primary,
        accent: $dark-theme-accent )));

.dark-theme {
  @include mat.all-component-colors($dark-theme);
}

// @media (prefers-color-scheme: dark) {
//   @include mat.all-component-colors($dark-theme);
// }

/* Global styles */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


.splash {
  opacity: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  background: #b9bbbee3;
  padding-top: 25vh;
  text-align: center;
  z-index: -1;
  transition: opacity 0.8s ease-out;
}

app-root:empty+.splash {
  opacity: 1;
  z-index: 100;
  transition: opacity 0.8s ease-in;
}
